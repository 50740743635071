<template>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol md="7">
                      <h4>Wallet Balance List</h4> 
                </CCol>               
                <CCol md="5">
                     <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
                </CCol>
                <!-- <CCol md="2">       
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>            
                </CCol> -->
            </CRow>
          </CCardHeader><br/>
          <CCardBody> 
  
                <CRow>
                  <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
                </CRow>  
  
                <div class="text-center">
                  <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>   
  
                <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                    <b-card border-variant="dark" header="Protected Excel Password" align ="center">
                      <b-card-text>
                          <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                      </b-card-text>                                                                      
                      <b-button-group>
                          <b-button v-on:click="actionCancel()">Cancel</b-button>
                          <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                      </b-button-group>                                   
                    </b-card>
                </b-card-group>
  
          <div class="table-responsive table">
            <b-table striped hover  :items="items" :fields="fields" sort-by="createDate" sort-desc="true" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter">                
                
                <template #cell(index)="{ item }">
                  {{ items.indexOf(item) + 1 }}
                </template>  

                <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate }}
                </template>

                <template v-slot:cell(actions)="{ item }">  
                    <CButton color="primary" @click="ActionDownload(item.filename)">Download</CButton>                  
                </template>

            </b-table>
          </div>
  
          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>      
          </div>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </template>
  
  <script>
  import ReportService from '@/api/ReportService.js';
  import moment from 'moment';
   
   export default {
      name: "walletbalancelist",
      components: {},     
      data() {
        return {
          spinner:false,
          list:[],
          columns : [],          
          data : [],
          items:[],             
          fields: [
            { key: 'index',label : '#'},
            { key: 'createDate',label : 'Created Date',sortable: true },
            { key: 'filename',label : 'File Name', sortable: true }, 
            { key: 'actions',  label: 'Actions'},     
          ],          
          currentPage: 1,
          perPage: 10,
          filter: null,
          formPasswd:false,         
          password:"",
          seen:false,
          msg :'',
          color:''
        };
      },
  
      filters: {
          upper(value){
            if(!value) return ''
            value = value.toString()
            return value.toUpperCase()
          },
          lower(value){
             if(!value) return ''
             value = value.toString()
             return value.toLowerCase()        
          },
          dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,10)            
          },
          IdSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,5) + '...'            
          },
          formatNum(value){ console.log(value);
             if(!value) return 0  
             return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
          },          
      },
  
      created () {
         this.getWalletBalanceReportList();
      },
      methods: { 

        toMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber - 1);

          return date.toLocaleString('en-US', {
            month: 'long',
          });
        },

        ActionDownload(filename){ 
          var sub = filename.split("_");
          var strdate  = (sub[0].length == 1) ? "0"+sub[0] : sub[0]; 
          var strmonth = (sub[1].length == 1) ? "0"+sub[1] : sub[1];
          this.password = strdate+strmonth+sub[2];
          this.downloadExcelReport(filename);
        },
  
        showFormPasswd(){
           this.formPasswd = true;
        },
  
        actionCancel(){
            this.formPasswd = false;
        },
         
        setDefaultPasswd(){
          var today = moment().format('DDMMYYYYHHmmss');
          var user  = this.$store.getters.getUser; 
          this.password = user+today;
        },

        getFilterData(){          
           this.data  = this.list;
        }, 

        sortByProperty(property){  
          return function(a,b){  
              if(a[property] > b[property])  
                return 1;  
              else if(a[property] < b[property])  
                return -1; 
              return 0;  
          }  
        },
  
        getReportExcel: function(params) {
          ReportService.getReportExcel(params).then(resp => {             
           try {  
              if(resp.status==200){
                 var filename = this.filename;
                 const link = document.createElement('a');    
                 link.href = window.URL.createObjectURL(
                     new Blob([resp.data])
                 );   
                 link.setAttribute('download', filename);
                 document.body.appendChild(link);
                 link.click(); this.spinner = false; 
              } else {
                this.msg   = 'Sorry, Generated Excel Failed!'; 
                this.color = 'warning'; this.seen  = true;
                this.spinner = false; 
                setTimeout( () =>  this.seen=false , 5000);
              }
             }
           catch(err) {            
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
           } 
  
          }, error => { 
             this.spinner = false;            
          });
        }, 
        
        downloadExcelReport: function(key) {
          ReportService.downloadExcelReport(key).then(resp => {
            try {  
              if(resp.status==200){
                var filename = key;
                const link = document.createElement('a');    
                link.href = window.URL.createObjectURL(
                    new Blob([resp.data])
                );   
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click(); 
                this.msg   = 'Your Excel Password is '+ this.password; 
                this.color = 'info'; 
              } else {
                this.msg   = 'Sorry, Generated Excel Failed!'; 
                this.color = 'warning'; 
              }
              this.seen    = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 8000);
           } catch(err) {            
                this.msg   = 'Sorry, Generated Excel Failed!'; 
                this.color = 'warning'; this.seen  = true;
                this.spinner = false; 
                setTimeout( () =>  this.seen=false , 5000);
          } 

        }, error => {
          this.loading = false;
        });
        },
          
        getWalletBalanceReportList: function() {
          ReportService.getWalletBalanceReportList().then(resp => {  
             let item = {}; var rows = []; 
             for (var key in resp) {  
                var filename = resp[key]; 
                var sub = filename.split("_"); 
                var strdate = (sub[0].length == 1) ? "0"+sub[0] : sub[0]; 
                item['createDate'] = strdate+" "+this.toMonthName(sub[1])+" "+sub[2];
                item['filename']   = filename;  rows.push(item);              
                item = {}; 
             } 
             rows.sort(this.sortByProperty("createDate"));
             this.items = rows;
          }, error => {
             this.spinner = false;
          });
        }
      }
   };   
  </script>
  <style>
      .table{ overflow-y:scroll; }
  </style>